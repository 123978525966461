<template>
  <b-card
    title="Main info"
  >
    <b-card-text>

      <b-table-simple
        class="mb-4"
      >
        <b-tbody>
          <b-tr>
            <b-th
              class="p-1"
            >Name</b-th>
            <b-td
              class="p-1"
            >{{ styleData.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Status</b-th>
            <b-td
              class="p-1"
            >{{ styleData.status }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Brand Ref</b-th>
            <b-td
              class="p-1"
            >{{ styleData.ref }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Manufacturer Ref</b-th>
            <b-td
              class="p-1"
            >{{ styleData.manufacturer_ref }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Category</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.category, categoryOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Collection</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.collection, collectionOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Brand</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.brand, brandOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Brand Contact</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.brand_contact_user, brandContactUserOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Manufacturer</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.manufacturer, manufacturerOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Manufacturer Contact</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.manufacturer_contact_user, manufacturerContactUserOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Agent</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.agent, agentOptions) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="p-1"
            >Agent Contact</b-th>
            <b-td
              class="p-1"
            >{{ getText(styleData.agent_contact_user, agentContactUserOptions) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTableSimple, BTbody, BTd, BTh, BTr,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BTr,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
    styleData: {
      type: Object,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    collectionOptions: {
      type: Array,
      required: true,
    },
    brandContactUserOptions: {
      type: Array,
      required: true,
    },
    manufacturerOptions: {
      type: Array,
      required: true,
    },
    manufacturerContactUserOptions: {
      type: Array,
      required: true,
    },
    agentOptions: {
      type: Array,
      required: true,
    },
    agentContactUserOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const getText = (value, options) => {
      const found = Array.from(options)
        .find(option => option.value === value)
      return found ? found.label : value
    }
    return {
      getText,
    }
  },
}
</script>

<style>

</style>

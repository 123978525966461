<template>
  <div>
    <b-card
      title="Prices"
    >
      <b-card-text>
        <div
          v-if="seePrices && fabrics.length"
        >
          <h4>Fabrics</h4>
          <b-table-simple
            class="mb-4"
          >
            <b-thead>
              <b-tr>
                <b-th
                  class="p-1 w-25"
                >Category type</b-th>
                <b-th
                  class="p-1"
                >Description</b-th>
                <b-th
                  class="p-1 w-25"
                >Price</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="material in fabrics"
                :key="material.id"
              >
                <b-td
                  class="p-1"
                >{{ material.category_type }}</b-td>
                <b-td
                  class="p-1"
                >
                  <div class="align-text-top">
                    {{ material.specifics_description }}
                  </div>
                  <div class="align-text-top">
                    {{ material.dimensions_description }}
                  </div>
                </b-td>
                <b-td class="p-1">
                  <div
                    v-if="material.consumption.quantity && material.price"
                  >
                    {{ material.consumption.quantity }} x {{ material.price }}
                    {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div
          v-if="seePrices && trims.length"
        >
          <h4>Trims</h4>
          <b-table-simple
            class="mb-4"
          >
            <b-thead>
              <b-tr>
                <b-th
                  class="p-1 w-25"
                >Category type</b-th>
                <b-th
                  class="p-1"
                >Description</b-th>
                <b-th
                  class="p-1 w-25"
                >Price</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="material in trims"
                :key="material.id"
              >
                <b-td
                  class="p-1"
                >{{ material.category_type }}</b-td>
                <b-td
                  class="p-1"
                >
                  <div class="align-text-top">
                    {{ material.specifics_description }}
                  </div>
                  <div class="align-text-top">
                    {{ material.dimensions_description }}
                  </div>
                </b-td>
                <b-td class="p-1">
                  <div
                    v-if="material.consumption.quantity && material.price"
                  >
                    {{ material.consumption.quantity }} x {{ material.price }}
                    {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div
          v-if="seePrices && embellishments.length"
        >
          <h4>Embellishments</h4>
          <b-table-simple
            class="mb-4"
          >
            <b-thead>
              <b-tr>
                <b-th
                  class="p-1 w-25"
                >Category type</b-th>
                <b-th
                  class="p-1"
                >Description</b-th>
                <b-th
                  class="p-1 w-25"
                >Price</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="embellishment in embellishments"
                :key="embellishment.id"
              >
                <b-td
                  class="p-1"
                >{{ embellishment.category_type }}</b-td>
                <b-td
                  class="p-1"
                >
                  <div class="align-text-top">
                    {{ embellishment.specifics_description }}
                  </div>
                  <div class="align-text-top">
                    {{ embellishment.dimensions_description }}
                  </div>
                </b-td>
                <b-td
                  class="p-1 w-25"
                >{{ embellishment.price }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div
          v-if="seePrices && packaging.length"
        >
          <h4>Packaging</h4>
          <b-table-simple
            class="mb-4"
          >
            <b-thead>
              <b-tr>
                <b-th
                  class="p-1 w-25"
                >Category type</b-th>
                <b-th
                  class="p-1"
                >Description</b-th>
                <b-th
                  class="p-1 w-25"
                >Price</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="material in packaging"
                :key="material.id"
              >
                <b-td
                  class="p-1"
                >{{ material.category_type }}</b-td>
                <b-td
                  class="p-1"
                >
                  <div class="align-text-top">
                    {{ material.specifics_description }}
                  </div>
                  <div class="align-text-top">
                    {{ material.dimensions_description }}
                  </div>
                </b-td>
                <b-td class="p-1">
                  <div
                    v-if="material.consumption.quantity && material.price"
                  >
                    {{ material.consumption.quantity }} x {{ material.price }}
                    {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div
          v-if="seePrices && styleData.price_internal_notes"
          class="mb-4"
        >
          <h4>Price Internal Notes</h4>
          <div
            style="white-space: pre-line"
          >{{ styleData.price_internal_notes }}
          </div>

        </div>
        <div
          v-if="styleData.price_notes"
        >
          <h4>Price Notes</h4>
          <div
            style="white-space: pre-line"
          >{{ styleData.price_notes }}
          </div>
        </div>
      </b-card-text>
    </b-card>
    <b-card
      v-if="feed.length > 0"
    >
      <b-card-text>
        <feed
          ref="refFeed"
          :feed="feed"
          :feed-config="feedConfig"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,

    Feed,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    seePrices: {
      type: Boolean,
      required: true,
    },
    fabrics: {
      type: Array,
      required: true,
    },
    trims: {
      type: Array,
      required: true,
    },
    packaging: {
      type: Array,
      required: true,
    },
    embellishments: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }
    const feed = computed(() => props.styleData.feed
      .filter(item => item.feed_vars.custom_type === 'Prices'))
    return {
      feedConfig,
      feed,
    }
  },
}
</script>

<style lang="css">
.sample-card-box {
  flex: 260px 0;
}
</style>

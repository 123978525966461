<template>
  <div>
    <b-card
      title="Sizes and Measurements"
    >
      <b-card-text>
        <b-container
          v-show="gridSizes.length > 0"
          class="mb-2"
        >
          <b-row>
            <b-col>
              <label for="sizes">Sizes</label>
              <div id="sizes">
                <b-button
                  v-for="size in gridSizes"
                  :key="size.key"
                  pill
                  disabled
                >
                  {{ size.label }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container
          v-show="gridSizes.length > 0"
          class="mb-2"
        >
          <b-row>
            <b-col>
              <label for="pom-table">POM Table</label>
              <div id="pom-table">
                <vue-excel-editor
                  v-model="styleData.sizes"
                  no-paging
                  no-header-edit
                  no-footer
                  disable-panel-filter
                  disable-panel-setting
                  readonly
                >
                  <vue-excel-column
                    field="ref"
                    label="REF"
                    width="50px"
                    type="string"
                    :init-style="{fontWeight: 'bold', textAlign: 'right'}"
                  />
                  <vue-excel-column
                    field="description"
                    label="Measurement"
                    width="200px"
                    type="string"
                  />
                  <vue-excel-column
                    field="tol"
                    label="Tol (+/-)"
                    width="50px"
                    type="string"
                  />
                  <template v-for="col in gridSizes">
                    <vue-excel-column
                      v-if="!col.hide"
                      :key="col.key"
                      :field="col.field"
                      :label="col.label"
                      :width="col.width"
                      type="string"
                      :init-style="col.field === styleData.sample_size ? {
                        fontWeight: 'bold', backgroundColor: '#e9ecef'
                      } : {}"
                    />
                  </template>
                </vue-excel-editor>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>

    <b-card
      v-if="styleData.construction_details"
      title="Construction details"
    >
      <b-card-text>
        <div>{{ styleData.construction_details }}</div>
      </b-card-text>
    </b-card>

    <b-card
      v-if="feed.length > 0"
    >
      <b-card-text>
        <feed
          ref="refFeed"
          :feed="feed"
          :feed-config="feedConfig"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BRow,

    Feed,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const gridSizes = ref(props.styleData.sizes_ordering.map(size => ({
      key: size,
      field: size.toUpperCase(),
      label: size.toUpperCase(),
      width: '51px',
    })))

    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }
    const feed = computed(() => props.styleData.feed
      .filter(item => item.feed_vars.custom_type === 'Sizes and Measurements'))

    return {
      gridSizes,
      feedConfig,
      feed,
    }
  },
}
</script>

<style>

</style>

<template>
  <div>
    <b-card
      title="Files"
    >
      <b-card-text>
        <b-container>
          <b-row>
            <template
              v-for="media in styleMedia"
            >
              <b-col
                v-if="!(isBrand && media.is_internal)"
                :key="media.id"
                no-body
                class="file-card-box"
              >
                <b-card>
                  <b-card-text
                    text-tag="div"
                  >
                    <div :class="media.is_cover ? 'image-file iscover' : 'image-file'">
                      <b-img
                        :alt="`${media.name}`"
                        rounded
                        :blank="!media.image_medium"
                        blank-color="#f2f2f2"
                        :src="media.image_medium"
                      />
                      <div
                        class="media-name"
                        :title="media.name"
                      >
                        {{ media.name }}
                      </div>
                    </div>
                    <div class="mt-1 d-flex flex-row">
                      <!-- Tag -->
                      <b-badge
                        v-for="tag in splitTag(media.tag)"
                        :key="tag"
                        pill
                        variant="secondary"
                        class="mt-1 mb-1"
                      >{{ tag }}</b-badge>

                      <!-- Internal -->
                      <b-badge
                        v-if="media.is_internal"
                        pill
                        variant="primary"
                        class="mt-1 mb-1"
                      >
                        Internal
                      </b-badge>
                    </div>
                    <div
                      v-if="media.info_note"
                    >
                      <feather-icon
                        icon="InfoIcon"
                        class="d-inline"
                      />
                      {{ media.info_note }}
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </template>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BCol, BContainer, BImg, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BImg,
    BRow,
  },
  props: {
    styleMedia: {
      type: Array,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isBrand = props.userRole === 'brand-manager' || props.userRole === 'brand'
    const splitTag = tag => {
      if (!tag) {
        return []
      }
      return tag.split(',')
    }
    return {
      isBrand,
      splitTag,
    }
  },
}
</script>
<style lang="scss" scoped>
.file-card-box {
  flex: 0 1 300px;
  overflow:hidden;
}
.image-file img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  vertical-align: middle;
  border-radius: 15px;
}
.image-file .nopreview {
  object-fit: cover;
  width: 100%;
  height: 180px;
  display:flex;
}
.noimage-file {
  margin: 0 auto 0 auto;
  height: 180px;
  border-radius: 15px;
}
.iscover {
  outline:10px solid;
  border-radius: 15px;
}
.media-name {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

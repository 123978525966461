<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="styleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching style data
      </h4>
      <div class="alert-body">
        No style found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-styles-list'}"
        >
          Styles
        </b-link>
        list.
      </div>
    </b-alert>

    <template v-if="styleData">
      <b-row>
        <b-col
          cols="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <div class="d-flex mt-1 mb-1 justify-content-start">
                <div class="mr-5">
                  <b-media
                    left-align
                    vertical-align="center"
                  >
                    <template #aside>
                      <b-img
                        v-if="styleData.cover_image"
                        rounded
                        height="64"
                        :src="styleData.cover_image"
                      />
                    </template>
                    <h4 class="card-title mt-0 mb-1">
                      {{ styleData.name }}
                    </h4>
                    <h6 class="card-subtitle text-muted">
                      {{ styleData.ref }}
                      <b-link
                        v-if="styleData.copied_from"
                        title="Original copy"
                        target="_blank"
                        :to="{ name: 'apps-styles-view', params: { id: styleData.copied_from }}"
                      >
                        <feather-icon
                          icon="CopyIcon"
                          size="16"
                          class="mx-1"
                        />
                      </b-link>
                    </h6>
                    <h6
                      v-if="styleData.collection"
                      class="card-subtitle mt-0"
                    >
                      <b-link
                        :to="{ name: 'apps-styles-list', query: { collectionFilter: styleData.collection } }"
                      >{{ styleData.collection_name }}</b-link>
                    </h6>
                  </b-media>
                </div>
                <div class="mr-5">
                  <h5 class="mt-0 mb-1 text-muted">
                    Category
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ categoryText(styleData.category) }}
                  </h6>
                </div>
                <div class="mr-5">
                  <h5 class="mt-0 mb-1 text-muted">
                    Brand
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ styleData.brand_name }}
                  </h6>
                </div>
                <div>
                  <h5 class="mt-0 mb-1 text-muted">
                    Status
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ styleData.status }}
                  </h6>
                </div>
              </div>
              <div>
                <div>
                  <b-dropdown
                    variant="link"
                    no-caret
                    dropleft
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'apps-styles-edit',
                             params: { id: styleData.id } }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit Style</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{ name: 'apps-styles-copy',
                             params: { id: styleData.id } }"
                    >
                      <feather-icon icon="CopyIcon" />
                      <span class="align-middle ml-50">Copy Style</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- Main info -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-main
            :user-role="userRole"
            :style-data="styleData"
            :category-options="categoryOptions"
            :brand-options="brandOptions"
            :collection-options="collectionOptions"
            :brand-contact-user-options="brandContactUserOptions"
            :manufacturer-options="manufacturerOptions"
            :manufacturer-contact-user-options="manufacturerContactUserOptions"
            :agent-options="agentOptions"
            :agent-contact-user-options="agentContactUserOptions"
          />
        </b-col>
      </b-row>
      <!-- Materials -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-materials
            title="Fabrics"
            :fetch-materials="fetchStyleFabrics"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-materials
            title="Trims"
            :fetch-materials="fetchStyleTrims"
          />
        </b-col>
      </b-row>
      <!-- Embellishments -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-materials
            title="Embellishments"
            :fetch-materials="fetchStyleEmbellishments"
          />
        </b-col>
      </b-row>
      <!-- Sizes -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-sizes
            :style-data="styleData"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-materials
            title="Packaging"
            :fetch-materials="fetchStylePackaging"
          />
        </b-col>
      </b-row>
      <!-- Files -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-files
            :style-media="styleMedia"
            :user-role="userRole"
          />
        </b-col>
      </b-row>
      <!-- Sample Requests -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-sample-requests
            :style-data="styleData"
            :raw-sample-requests="rawSampleRequests"
          />
        </b-col>
      </b-row>
      <!-- Prices -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-view-prices
            :style-data="styleData"
            :see-prices="seePrices"
            :fabrics="fabrics"
            :trims="trims"
            :embellishments="embellishments"
            :packaging="packaging"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  computed, ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import {
  BAlert, BCard, BCol, BDropdown, BDropdownItem, BImg, BLink, BMedia, BRow,
} from 'bootstrap-vue'

import styleStoreModule from '../styleStoreModule'
import StyleViewMain from './StyleViewMain.vue'
import StyleViewMaterials from './StyleViewMaterials.vue'
import StyleViewSizes from './StyleViewSizes.vue'
import StyleViewFiles from './StyleViewFiles.vue'
import StyleViewSampleRequests from './StyleViewSampleRequests.vue'
import StyleViewPrices from './StyleViewPrices.vue'

import useStyleMaterialsList from '../styles-edit/useStyleMaterialsList'

export default {
  components: {
    BAlert,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BImg,
    BLink,
    BMedia,
    BRow,

    StyleViewMain,
    StyleViewMaterials,
    StyleViewSizes,
    StyleViewFiles,
    StyleViewSampleRequests,
    StyleViewPrices,
  },
  setup(props, { root }) {
    const styleData = ref(null)
    const styleMedia = ref([])
    const rawSampleRequests = ref([])
    const fabrics = ref([])
    const trims = ref([])
    const embellishments = ref([])
    const packaging = ref([])
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const seePrices = userRole === 'manufacturer-manager' || userRole === 'manufacturer' || userRole === 'platform-manager'

    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })
    const categoryText = computed(() => category => {
      const found = Array.from(categoryOptions.value)
        .find(option => option.value === category)
      return found ? found.label : category
    })
    const brandOptions = computed(() => {
      const options = root.$store.getters['app-style/brandOptions']
      return options
    })
    const collectionOptions = computed(() => {
      const options = root.$store.getters['app-style/collectionOptions']
      return options
    })
    const brandContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/brandContactUserOptions']
      return options
    })
    const manufacturerOptions = computed(() => {
      const options = root.$store.getters['app-style/manufacturerOptions']
      return options
    })
    const manufacturerContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/manufacturerContactUserOptions']
      return options
    })
    const agentOptions = computed(() => {
      const options = root.$store.getters['app-style/agentOptions']
      return options
    })
    const agentContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/agentContactUserOptions']
      return options
    })

    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-style/fetchStyle', { id: router.currentRoute.params.id })
        .then(response => { styleData.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            styleData.value = undefined
          }
        })
    }
    const fetchStyleMedia = () => {
      store.dispatch('app-style/fetchStyleMedia', { id: router.currentRoute.params.id })
        .then(response => { styleMedia.value = response.data.style_media })
        .catch(error => {
          if (error.response.status === 404) {
            styleMedia.value = []
          }
        })
    }
    const fetchStyleSampleRequests = () => {
      store.dispatch('app-style/fetchStyleSampleRequests', { style: router.currentRoute.params.id })
        .then(response => { rawSampleRequests.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            rawSampleRequests.value = []
          }
        })
    }

    const fetchStyleFabricsPrices = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleFabrics', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          fabrics.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            fabrics.value = []
          }
        })
    }

    const fetchStyleTrimsPrices = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleTrims', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          trims.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            trims.value = []
          }
        })
    }

    const fetchStylePackagingPrices = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleFabrics', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          packaging.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            packaging.value = []
          }
        })
    }

    const fetchStyleEmbellishmentsPrices = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleEmbellishments', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          embellishments.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            embellishments.value = []
          }
        })
    }

    const {
      fetchStyleFabrics,
      fetchStyleTrims,
      fetchStylePackaging,
      fetchStyleEmbellishments,
    } = useStyleMaterialsList()

    onMounted(optionsInit)
    onMounted(fetchStyle)
    onMounted(fetchStyleMedia)
    onMounted(fetchStyleSampleRequests)
    onMounted(fetchStyleFabricsPrices)
    onMounted(fetchStyleTrimsPrices)
    onMounted(fetchStyleEmbellishmentsPrices)
    onMounted(fetchStylePackagingPrices)

    return {
      styleData,
      styleMedia,
      rawSampleRequests,
      userRole,
      seePrices,
      fabrics,
      trims,
      embellishments,
      packaging,

      fetchStyleFabrics,
      fetchStyleTrims,
      fetchStyleEmbellishments,
      fetchStylePackaging,
      categoryOptions,
      categoryText,
      brandOptions,
      collectionOptions,
      brandContactUserOptions,
      manufacturerOptions,
      manufacturerContactUserOptions,
      agentOptions,
      agentContactUserOptions,
    }
  },
}
</script>

<style>

</style>
